<template>
  <el-table :data="list">
    <el-table-column label="方案名称">
      <template #default="{ row: item }">
        <a :href="item.filePath" :download="item.fileName"> {{ item.fileName }}</a>

      </template>

    </el-table-column>
    <el-table-column label="批准文件">
      <template #default="{ row: item }">

        <ShowRelBtn :id="item.doc_Id" @link="lnkToDocContent">
          <i class="el-icon-link"></i>
          {{ item.doc_Compile_No }} - {{ item.doc_Title }}
        </ShowRelBtn>

      </template>

    </el-table-column>
  </el-table>
</template>
<script lang="ts">
import ShowRelBtn from "@/components/showRelBtn.vue";
import lnkToDocContent from "@/views/officialDoc/lnkToDocContent"
import { defineComponent, inject, ref, onMounted, reactive, toRefs, toRef, watch } from "vue";
import request from "@/utils/request";
import { dicLabel, planBackupStatusText } from "@/network/lookUp.ts";

export default defineComponent({
  props: ["id"],
  components: { ShowRelBtn },
  setup(props) {
    const list = ref([]);

    onMounted(() => {
      return request
        .get(`/heritageUnit/plans/${props.id}`)
        .then((res) => {
          list.value = res;
        });
    });
    return {
      lnkToDocContent,
      list,
      planBackupStatusText,
      dicLabel,

    };

  },
});
</script>
