
import LogHistory from "@/views/shared/logHistory.vue";
import { defineComponent, inject, ref, onMounted, reactive, toRefs, toRef, watch } from "vue";
import request from "@/utils/request";
import { dicLabel, things, thingApplyStatusText } from "@/network/lookUp.ts";

export default defineComponent({
    props: ["id"],
    setup(props) {
        const list = ref([]);

        onMounted(() => {
            return request
                .get(`/heritageUnit/things/${props.id}`)
                .then((res) => {
                    list.value = res;
                });
        });
        return {
            things,
            list,
            thingApplyStatusText,
            dicLabel,

        };

    },
});
