import { createVNode, ref, render, nextTick, reactive } from "vue";
import LnkToDocContent from "./lnkToDocContent.vue";
import app from "@/main";
let component = null;
const setting = reactive({ id: 0, visible: false });

export default function lnkToDocContent(id) {
  if (!component) {
    nextTick(() => {
      component = createVNode(LnkToDocContent, { setting });
      component.appContext = app._context;
      const div = document.createElement("div");
      render(component, div);
      document.body.appendChild(div);
    });
  }
  setting.id = id;
  setting.visible = true;
  return false;
}
