<template>
    <el-table :data="list">
        <el-table-column label="地区" prop="areaName"></el-table-column>
        <el-table-column label="文保单位" prop="heritageUnitName"></el-table-column>
        <el-table-column label="事项编码" prop="applyCode" width="100"></el-table-column>
        <el-table-column label="事项名称" prop="thingName" width="140" align="center">
            <template #default="{ row: item }">
                {{
                    things.find(p => p.code == item.applyCode)?.thingsName + (item.ApplyType ?
                        `(${things.find(p => p.applyType == item.applyType)?.applyTypeName})` : '')
                }}
            </template>
        </el-table-column>

        <el-table-column label="申请文件标题" prop="applyFileTitle">
            <template #default="{ row: item }">
                <el-button type="text"
                    @click="$router.push({ name: 'ReportAndReviewThingsDetail', params: { id: item.id } })">{{
                        item.applyFileTitle
                    }}</el-button>
            </template>
        </el-table-column>
        <el-table-column label="项目名称" prop="projectName" align="center"></el-table-column>
        <el-table-column label="申请单位" prop="applyPerson" align="center"></el-table-column>
        <el-table-column label="经办人" prop="doPerson" align="center"></el-table-column>
        <el-table-column label="状态" prop="status" width="100">
            <template #default="{ row: item }">{{ thingApplyStatusText[item.status] }}</template>
        </el-table-column>

        <!-- <el-table-column label="操作">
            <template #default="{ row: item }">
                <el-button type="text" icon="el-icon-copy-document" @click="doQueryHistory(item.id)" size="small">日志
                </el-button>
            </template>
        </el-table-column> -->

    </el-table>
</template>
<script lang="ts">
import LogHistory from "@/views/shared/logHistory.vue";
import { defineComponent, inject, ref, onMounted, reactive, toRefs, toRef, watch } from "vue";
import request from "@/utils/request";
import { dicLabel, things, thingApplyStatusText } from "@/network/lookUp.ts";

export default defineComponent({
    props: ["id"],
    setup(props) {
        const list = ref([]);

        onMounted(() => {
            return request
                .get(`/heritageUnit/things/${props.id}`)
                .then((res) => {
                    list.value = res;
                });
        });
        return {
            things,
            list,
            thingApplyStatusText,
            dicLabel,

        };

    },
});
</script>
