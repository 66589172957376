
import ShowRelBtn from "@/components/showRelBtn.vue";
import lnkToDocContent from "@/views/officialDoc/lnkToDocContent"
import { defineComponent, inject, ref, onMounted, reactive, toRefs, toRef, watch } from "vue";
import request from "@/utils/request";
import { dicLabel, planBackupStatusText } from "@/network/lookUp.ts";

export default defineComponent({
  props: ["id"],
  components: { ShowRelBtn },
  setup(props) {
    const list = ref([]);

    onMounted(() => {
      return request
        .get(`/heritageUnit/plans/${props.id}`)
        .then((res) => {
          list.value = res;
        });
    });
    return {
      lnkToDocContent,
      list,
      planBackupStatusText,
      dicLabel,

    };

  },
});
