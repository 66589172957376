<template>

  <el-dialog v-model="visible" :title="title" width="80%">
    <el-tabs type="border-card" v-model="tabActiveName" v-loading="isLoading">
      <el-tab-pane label="单位简介" name="1">
        <el-container>
          <!-- <el-aside width="450px">
            <el-image v-if="form.images != null" :src="`http://sdfund.hpmanage.com/${form.images}`"></el-image>
          </el-aside> -->
          <el-main>
            <div v-html="form.summary" v-if="form.heritageLevel_Code == '1'"></div>
            <div v-else v-html="form.summary">
            </div>
          </el-main>
        </el-container>
      </el-tab-pane>
      <el-tab-pane label="基本情况" name="2">
        <el-collapse v-model="baseActiveNames">
          <el-collapse-item title="基本信息" name="1">
            <el-form v-model="form" label-width="200px">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="单位名称">
                    {{ form.heritage_Name }}
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="单位编号">
                    {{ form.heritage_Code }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="所属省份"> 山东省 </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="公布批次">
                    {{ form.heritageBatch_Name }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="公布类型">
                    {{ form.heritageClass_Name }}
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="所属类型">
                    {{ form.institute_Type_Name }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="公布时代">
                    {{ form.heritageAge_Name }}
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="公布地址"> </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col>
                  <el-form-item label="详细地址"> </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="经度"> </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="纬度"> </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="海拔高程"> </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="测点说明"> </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="建筑面积"> </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="占地面积"> </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <el-form-item label="保存状况"> </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="保存方式"> </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <el-form-item label="自然损毁原因"> </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="人为损毁原因"> </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-collapse-item>
          <el-collapse-item title="所有权及使用情况" name="2">
            <el-form v-model="form" label-width="200px">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="所有权单位（人）名称"> </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="所有权性质"> </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="隶属上级部门名称：
">
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="所属行业：
">
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="管理机构：
">
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="是否使用/利用：
">
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="用途：
">
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="现使用单位（人）名称：
">
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="隶属上级部门名称：
">
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="所属行业：
">
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <el-form-item label="属地文物管理机构名称：
">
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="是否对外开放：
">
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="年均游客量：

">
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="日最大游客承载量：

">
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="年均门票收入：

">
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="被列为其他国家级称号：

">
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="为少数民族代表性文物：


">
                  </el-form-item>
                </el-col>
                <el-col :span="12"> </el-col>
              </el-row>
            </el-form>
          </el-collapse-item>
        </el-collapse>
      </el-tab-pane>
      <el-tab-pane label="主要组成" name="3">
        <el-table>
          <el-table-column label="文物名称"> </el-table-column>
          <el-table-column label="文物时代"> </el-table-column>
          <el-table-column label="是否开放"> </el-table-column>
          <el-table-column label="文物位置"> </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="保护区划" name="4">
        <el-table>
          <el-table-column label="保护区划点名称"> </el-table-column>
          <el-table-column label="规划项目名称"> </el-table-column>
          <el-table-column label="年份"> </el-table-column>
          <el-table-column label="编制阶段"> </el-table-column>
          <el-table-column label="批准文号"> </el-table-column>
        </el-table>
      </el-tab-pane>

      <el-tab-pane label="审核事项" name="5">
        <Things :id="form.id" v-if="form.id"></Things>
      </el-tab-pane>
      <el-tab-pane label="计划书" name="6">
        <Setups :id="form.id" v-if="form.id"></Setups>
      </el-tab-pane>
      <el-tab-pane label="项目方案" name="7">
        <Plans :id="form.id" v-if="form.id"></Plans>
      </el-tab-pane>
      <el-tab-pane label="资金安排情况" name="8">
        <FundProjects :id="form.id" v-if="form.id"></FundProjects>
      </el-tab-pane>
      <el-tab-pane label="资金使用情况" name="9">
        <Funds :id="form.id" v-if="form.id"></Funds>
      </el-tab-pane>
    </el-tabs>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import Funds from "./funds.vue"
import FundProjects from "./fundprojects.vue"
import Things from "./things.vue"
import Plans from "./plans.vue"
import Setups from "./setups.vue"
import { useFindHeritage } from "@/network/heritageUnit.ts";
import { defineComponent, toRefs, ref, reactive, computed, watch, onMounted } from "vue";

export default defineComponent({
  components: { Things, Plans, Setups, FundProjects, Funds },
  props: {
    setting: { type: Object },
  },
  setup(props, { emit }) {
    const tabActiveName = ref("1");

    const { visible, id } = toRefs(props.setting as any);
    const baseActiveNames = ref(["1", "2"]);
    const [isLoading, form, find] = useFindHeritage();
    // watch(visible, (v) => {
    //   if (v) {
    //     tabActiveName.value = "1";
    //     find(id.value);
    //   }
    // });
    onMounted(() => {
      tabActiveName.value = "1";
      find(id.value);
    })
    const title = computed(() => {
      return `${form.heritage_Name}(${form.heritage_Code})`;
    });

    return {
      tabActiveName,
      baseActiveNames,
      isLoading,
      title,
      visible,
      form,
    };
  },
});
</script>

<style scoped></style>