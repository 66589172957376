
import Funds from "./funds.vue"
import FundProjects from "./fundprojects.vue"
import Things from "./things.vue"
import Plans from "./plans.vue"
import Setups from "./setups.vue"
import { useFindHeritage } from "@/network/heritageUnit.ts";
import { defineComponent, toRefs, ref, reactive, computed, watch, onMounted } from "vue";

export default defineComponent({
  components: { Things, Plans, Setups, FundProjects, Funds },
  props: {
    setting: { type: Object },
  },
  setup(props, { emit }) {
    const tabActiveName = ref("1");

    const { visible, id } = toRefs(props.setting as any);
    const baseActiveNames = ref(["1", "2"]);
    const [isLoading, form, find] = useFindHeritage();
    // watch(visible, (v) => {
    //   if (v) {
    //     tabActiveName.value = "1";
    //     find(id.value);
    //   }
    // });
    onMounted(() => {
      tabActiveName.value = "1";
      find(id.value);
    })
    const title = computed(() => {
      return `${form.heritage_Name}(${form.heritage_Code})`;
    });

    return {
      tabActiveName,
      baseActiveNames,
      isLoading,
      title,
      visible,
      form,
    };
  },
});
