<template>
  <el-table :data="list">
    <el-table-column label="编号" prop="code"></el-table-column>
    <el-table-column label="地区" prop="areaName"></el-table-column>
    <el-table-column label="名称" prop="project_Name" width="400">
      <template #default="{ row: item }">
        <router-link :to="{
    name: `ReportAndReview${item.setupGroup == 1 ? 'Prov' : 'Nation'}SetupView`,
    params: { id: item.id },
  }">
          {{ item.project_Name }}</router-link>
      </template>
    </el-table-column>
    <el-table-column label="工程类型" prop="projectType">
      <template #default="{ row: item }">
        {{
    item.isSafeProject == 1
      ? dicLabel(item.safeProjectType, scheduleTypeDic)
      : dicLabel(item.projectType, scheduleTypeDic)
  }}
      </template>
    </el-table-column>
    <!-- <el-table-column label="年份" prop="project_Year"></el-table-column> -->
    <!-- <el-table-column label="地区" prop="areaName"></el-table-column> -->
    <el-table-column label="文保单位" prop="heritageUnitName">
      <template #default="{ row: item }">
        <a href="javascript:void()">{{ item.heritageUnitName }}</a>
      </template>
    </el-table-column>
    <el-table-column label="文保单位类型" prop="heritageUnitClassName">
    </el-table-column>
    <el-table-column label="是否革命文物" prop="heritageUnit_Name">
      <template #default="{ row: item }">
        {{ item.isRevolution ? "是" : "否" }}
      </template>
    </el-table-column>
    <el-table-column label="状态">
      <template #default="{ row: item }">{{
    setupProjectStatusText[item.status]
  }}</template>
    </el-table-column>
    <el-table-column label="编制人" prop="createUserName"></el-table-column>
  </el-table>
</template>
<script lang="ts">

import { defineComponent, inject, ref, onMounted, reactive, toRefs, toRef, watch } from "vue";
import request from "@/utils/request";
import { dicLabel, setupProjectStatusText, scheduleTypeDic } from "@/network/lookUp.ts";

export default defineComponent({
  props: ["id"],
  setup(props) {
    const list = ref([]);

    onMounted(() => {
      return request
        .get(`/heritageUnit/setups/${props.id}`)
        .then((res) => {
          list.value = res;
        });
    });
    return {
      list,
      setupProjectStatusText,
      dicLabel,
      scheduleTypeDic

    };

  },
});
</script>
