
import { defineComponent, inject, ref, onMounted, reactive, toRefs, toRef, watch } from "vue";
import request from "@/utils/request";


export default defineComponent({
  props: ["id"],
  setup(props) {
    const list = ref([]);

    onMounted(() => {
      return request
        .get(`/heritageUnit/funds/${props.id}`)
        .then((res) => {
          list.value = res;
        });
    });
    return {
      list
    };

  },
});
