<template>
  <el-table :data="list" >
    <el-table-column label="资金安排年度" prop="year"></el-table-column>
    <el-table-column label="市级/省直" prop="city_Name"></el-table-column>
    <el-table-column label="区县" prop="country_Name"></el-table-column>
    <el-table-column label="文保单位" prop="heritageUnit_Name"></el-table-column>
    <el-table-column label="项目编号" prop="project_Code"></el-table-column>
    <el-table-column label="项目名称" prop="project_Name"></el-table-column>
    <el-table-column label="已安排（万元）" prop="project_Fund_Amount"></el-table-column>
    <el-table-column label="已支出" prop="project_Fund_Amount_Used"></el-table-column>
    <el-table-column label="资金执行率" prop="fundDoPercent"></el-table-column>
    <el-table-column label="资金执行状态" prop="project_FundDo_Status"></el-table-column>
    <el-table-column label="执行进度说明" prop="fundDoSlowReason"></el-table-column>
  </el-table>
</template>
<script lang="ts">
import { defineComponent, inject, ref, onMounted, reactive, toRefs, toRef, watch } from "vue";
import request from "@/utils/request";


export default defineComponent({
  props: ["id"],
  setup(props) {
    const list = ref([]);

    onMounted(() => {
      return request
        .get(`/heritageUnit/funds/${props.id}`)
        .then((res) => {
          list.value = res;
        });
    });
    return {
      list
    };

  },
});
</script>
