<template>
  <el-table :data="list">
    <el-table-column label="地区" prop="areaName">
      <template #default="{ row: item }">
        {{ item.areaName }}
      </template>
    </el-table-column>

    <el-table-column label="项目编号" prop="project_Code"></el-table-column>
    <el-table-column label="项目名称" prop="project_Name" width="400">
      <template #default="{ row: item }">
        <a href="javascript:void()"> {{ item.project_Name }}</a>
      </template>
    </el-table-column>
    <el-table-column label="项目类型" prop="project_Classify_Name"></el-table-column>
    <el-table-column label="项目子类型" prop="project_SubClassify_Name"></el-table-column>
    <el-table-column label="文保单位" prop="heritageUnit_Name" width="120">
      <template #default="{ row: item }">
        <a href="javascript:void()"> {{ item.heritageUnit_Name }}</a>
      </template>
    </el-table-column>
    <el-table-column label="级别" prop="heritageUnit_LevelName">
    </el-table-column>
    <el-table-column label="批文" width="200">
      <template #default="{ row: item }">
        <template v-if="item.setupDoc_Id != null">
          计划书：{{ item.setupDoc_Name }}
        </template>
        <template v-if="item.proposalDoc_Id != null">
          方案：{{ item.proposalDoc_Name }}
        </template>
        <template v-else>
          <i class="el-icon-warning Red"></i> {{ item.noDoc_Note }}
        </template>
      </template>
    </el-table-column>

    <el-table-column label="方案设计单位" prop="planDesignUnit" width="100">
    </el-table-column>
    <el-table-column label="项目总概算" prop="budget_Total"></el-table-column>

    <el-table-column label="预算控制数" prop="assessment_Money"></el-table-column>
    <el-table-column label="累计安排" prop="totalGive_Money"></el-table-column>
    <el-table-column label="项目状态" prop="status_Name"></el-table-column>

  </el-table>
</template>
<script lang="ts">
import { defineComponent, inject, ref, onMounted, reactive, toRefs, toRef, watch } from "vue";
import request from "@/utils/request";


export default defineComponent({
  props: ["id"],
  setup(props) {
    const list = ref([]);

    onMounted(() => {
      return request
        .get(`/heritageUnit/fundprojects/${props.id}`)
        .then((res) => {
          list.value = res;
        });
    });
    return {
      list
    };

  },
});
</script>
